<template>
    <div class="con">
        <div class="home_img" v-html="content"></div>
        <button type="button" @click="startCeshi" class="start_btn">立即测试</button>
    </div>
</template>

<script>
    export default {
        name: "HomePage",
        data() {
            return {
                mess: "666",
                content: "",
                bid: ""
            }
        },
        created() {
            // 判断是否存在bd_vid
            const aa = this.$route.query.bd_vid;
            if(aa!=undefined){
                this.bid = aa;
            }
            // console.log(this.bid);
            this.getData();
        },
        methods: {
            startCeshi(){
                // console.log("开始测试");
                this.$router.push({
                    name: "gender",
                    query: {
                        bd_vid: this.bid,
                    }
                })
            },
            async getData() {
                const res = await this.request.get("/api/home");
                // console.log(res);
                if (res.code == 1) {
                    this.content = res.data.content;
                    this.$store.commit('replaceText', res.data.title);
                    document.title = this.$store.state.title;
                }
            }
        }
    }
</script>

<style scoped>
    .content {
        width: 100%;
    }

    .start_btn {
        position: fixed;
        bottom: 30px;
        left: 50%;
        background: #54A0FD;
        color: yellow;
        border-radius: 30px;
        border: 0px;
        width: calc(100% - 30px);
        height: 50px;
        font-size: 22px;
        font-weight: 500;
        max-width: 500px;
        animation: shan 1s alternate infinite;
        cursor: pointer;
        text-align: center;
        line-height: 50px;
        text-decoration: none;
    }

    @keyframes shan {
        0% {
            transform: translateX(-50%) scale(.9);
        }
        100% {
            transform: translateX(-50%) scale(1);
        }
    }
</style>