import Vue from "vue";
import Router from "vue-router";
import nProgress from 'nprogress'
import 'nprogress/nprogress.css'
import Home from "@/components/HomePage";
import Ceshi from "@/components/Ceshi";
import Result from "@/components/Result";
import Report from "@/components/Report";
import Gender from "@/components/Gender";
import Pay from "@/components/Pay";

Vue.use(Router);

const routes = [
    {
        path: "*",
        redirect: "/"
    },
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
        path: "/gender",
        name: "gender",
        component: Gender
    },
    {
        path: "/ceshi",
        name: "ceshi",
        component: Ceshi
    },
    {
        path: "/result",
        name: "result",
        component: Result
    },
    {
        path: "/pay",
        name: "pay",
        component: Pay
    },
    {
        path: "/report",
        name: "report",
        component: Report
    }
];

export default new Router({
    mode: "history",
    routes
});
