<template>
    <div class="con">
        <div class="gender_box">
            <div class="gender_t1">MBTI性格测试2024版</div>
            <div class="gender_t2">为确保测试结果的可信度<br/>请选择性别</div>
            <div class="gender_img">
                <div class="gender_img_item">
                    <img :class="active==0?'item_img_active2':''" @click="swichGender(0)" src="../assets/man.png" alt="">
                    <div>男生</div>
                </div>
                <div class="gender_img_item">
                    <img :class="active==1?'item_img_active1':''" @click="swichGender(1)" src="../assets/woman.png" alt="">
                    <div>女生</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Gender",
        data() {
            return {
                active: 2,
                bid: ""
            }
        },
        created() {
            document.title = this.$store.state.title;
            const aa = this.$route.query.bd_vid;
            if(aa!=undefined){
                this.bid = aa;
            }
        },
        methods: {
            swichGender(num){
                this.active = num;
                setTimeout(() => {
                    this.setOrder();
                },300)
            },
            async setOrder(){
                const data = {
                    ua: navigator.userAgent,
                    bid: this.bid,
                    gender: this.active
                };
                const res = await this.request.post("/api/add_order", data);
                if(res.code == 1) {
                    await this.$router.push({
                        name: 'ceshi',
                        query: {
                            trade_id: res.data.trade_id
                        }
                    })
                }
            },
        }
    }
</script>

<style scoped>
    .gender_box{
        box-sizing: border-box;
        position: relative;
        top: 80px;
        left: 20px;
        width: calc(100% - 40px);
        border: 1px solid silver;
        border-radius: 10px;
        padding: 50px 30px;
        font-size: 15px;
    }
    .gender_t1{
        font-size: 23px;
        font-weight: 600;
        text-align: center;
    }
    .gender_t2{
        font-size: 15px;
        line-height: 25px;
        text-align: center;
        color: gray;
        margin: 20px 0;
    }
    .gender_img{
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 30px;
    }
    .gender_img_item{
        text-align: center;
    }
    .gender_img_item img{
        width: 90px;
        height: 90px;
        border-radius: 100%;
        margin-bottom: 20px;
        border: 2px solid silver;
        /*禁止用户选中*/
        user-select: none;
    }
    .item_img_active1{
        border: 2px solid #FFC0CB !important;
    }
    .item_img_active2{
        border: 2px solid #54A0FD !important;
    }
</style>