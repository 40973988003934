<template>
    <div class="con" v-if="reportData.title">
        <div class="top_box">
            <div class="top_info">报告生成于：{{reportData.create_time}}</div>
            <div class="menu">
                <div @click="collect">收藏结果</div>
                <div @click="reTest">免费重测</div>
                <div @click="share">分享好友</div>
            </div>
        </div>
        <div class="title" style="margin-bottom: 20px"> 你的MBTI人格类型报告</div>
        <div class="content">
            <img class="con_img" src="../assets/top.png" alt="">
            <div class="result_box">
                <div class="box_left">
                    <div class="box_left_t1"><br>你的测试类型为：<br/>{{reportData.create_time}}</div>
                    <div class="box_left_t2">
                        <div class="box_left_t21">{{reportData.title}}</div>
                        <div class="box_left_t22">{{reportData.sub_title}}</div>
                    </div>
                </div>
                <div class="box_right">
                    <img :src="reportData.img_url" alt="">
                </div>
            </div>
            <div class="result_chart" v-if="reportData.result_chart!=undefined">
                <div class="result_item">
                    <div class="result_left">
                        <span>{{(reportData.result_chart[0].E*100).toFixed(0)}}%</span><br/>外向(E)
                    </div>
                    <div class="result_center">
                        <div :style="'width: '+ reportData.result_chart[0].E*100 +'%;height:10px;background: #8876ff'"></div>
                        <div :style="'width: '+ reportData.result_chart[0].I*100 +'%;height:10px;background: #6c9afc'"></div>
                    </div>
                    <div class="result_right">
                        <span>{{(reportData.result_chart[0].I*100).toFixed(0)}}%</span><br/>内向(I)
                    </div>
                </div>
                <div class="result_item">
                    <div class="result_left">
                        <span>{{(reportData.result_chart[1].S*100).toFixed(0)}}%</span><br/>实感(S)
                    </div>
                    <div class="result_center">
                        <div :style="'width: '+ reportData.result_chart[1].S*100 +'%;height:10px;background: #E4523B'"></div>
                        <div :style="'width: '+ reportData.result_chart[1].N*100 +'%;height:10px;background: #F39B1F'"></div>
                    </div>
                    <div class="result_right">
                        <span>{{(reportData.result_chart[1].N*100).toFixed(0)}}%</span><br/>直感(N)
                    </div>
                </div>
                <div class="result_item">
                    <div class="result_left">
                        <span>{{(reportData.result_chart[2].T*100).toFixed(0)}}%</span><br/>理性(T)
                    </div>
                    <div class="result_center">
                        <div :style="'width: '+ reportData.result_chart[2].T*100 +'%;height:10px;background: #CF6FF1'"></div>
                        <div :style="'width: '+ reportData.result_chart[2].F*100 +'%;height:10px;background: #593EC4'"></div>
                    </div>
                    <div class="result_right">
                        <span>{{(reportData.result_chart[2].F*100).toFixed(0)}}%</span><br/>感性(F)
                    </div>
                </div>
                <div class="result_item">
                    <div class="result_left">
                        <span>{{(reportData.result_chart[3].J*100).toFixed(0)}}%</span><br/>判断(J)
                    </div>
                    <div class="result_center">
                        <div :style="'width: '+ reportData.result_chart[3].J*100 +'%;height:10px;background: #3D9388'"></div>
                        <div :style="'width: '+ reportData.result_chart[3].P*100 +'%;height:10px;background: #96BF50'"></div>
                    </div>
                    <div class="result_right">
                        <span>{{(reportData.result_chart[3].P*100).toFixed(0)}}%</span><br/>知觉(P)
                    </div>
                </div>
                <div style="text-align: center;margin: 20px 0">你的性格类型仅占总人口的<strong>{{reportData.people}}%</strong></div>
            </div>
        </div>
        <div class="info_title">性格简介</div>
        <div class="info_box" v-html="reportData.introduction"></div>
        <div class="info_t1">
            <p>四个字母的含义</p>
            <p>Meaning of letters</p>
        </div>
        <div class="letter_box">
            <div v-for="(item,index) in reportData.letter" :key="item.word" class="letter_item">
                <div :class="'letter_left letter_left_bg'+index">{{item.word}}</div>
                <div class="letter_right">{{item.value}}</div>
            </div>
        </div>
        <div class="info_t1">
            <p>性格特征</p>
            <p>Personality traits</p>
        </div>
        <div class="info_box" v-html="reportData.character"></div>
        <div class="info_t1">
            <p>名人名家</p>
            <p>Celebrities and Masters</p>
        </div>
        <div class="info_box" v-html="reportData.celebrity"></div>
        <div class="other_box" v-if="reportData.values">
            <div class="other_item">
                <div class="info_t1">
                    <p>价值观</p>
                    <p>Values</p>
                </div>
                <div class="info_box" v-html="reportData.values"></div>
            </div>
            <div class="other_item">
                <div class="info_t1">
                    <p>性格的优势</p>
                    <p>Advantages of personality</p>
                </div>
                <div class="info_box" v-html="reportData.advantage"></div>
            </div>
            <div class="other_item">
                <div class="info_t1">
                    <p>性格的劣势</p>
                    <p>Disadvantages of personality</p>
                </div>
                <div class="info_box" v-html="reportData.disadvantage"></div>
            </div>
            <div class="other_item">
                <div class="info_t1">
                    <p>成长建议</p>
                    <p>Growth advice</p>
                </div>
                <div class="info_box" v-html="reportData.recommend"></div>
            </div>
            <div class="other_item">
                <div class="info_t1">
                    <p>荣格八维</p>
                    <p>Jungian Eight Dimensions</p>
                </div>
                <div class="info_box" v-html="reportData.rongge"></div>
            </div>
            <div class="other_item">
                <div class="info_t1">
                    <p>工作概述</p>
                    <p>Job Overview</p>
                </div>
                <div class="info_box" v-html="reportData.work"></div>
            </div>
            <div class="other_item">
                <div class="info_t1">
                    <p>职业参考</p>
                    <p>Career reference</p>
                </div>
                <div class="info_box" v-html="reportData.career"></div>
            </div>
            <div class="other_item">
                <div class="info_t1">
                    <p>恋爱宝典</p>
                    <p>Love Encyclopedia</p>
                </div>
                <div class="info_box" v-html="reportData.love"></div>
            </div>
        </div>
        <div class="report_btn" v-if="reportData.report_work">
            <div @click="showDetail(1)">职场报告</div>
            <div @click="showDetail(2)">婚恋报告</div>
            <div @click="showDetail(3)">八维报告</div>
        </div>
        <div class="report_box" v-if="isDetail">
            <div class="report_con">
                <h3>{{reportDetailTitle}}</h3>
                <div class="info_box" v-html="reportDetail"></div>
                <div style="height: 50px"></div>
            </div>
            <div class="report_close" @click="showDetail(0)">返回</div>
        </div>
        <div style="height: 50px"></div>
        <transition name="fade">
            <div class="code_bg" v-if="isCode">
                <div class="code_box" v-if="codeNum==0">
                    <div class="code_close" @click="share">×</div>
                    <div class="code_t1">微信扫码关注公众号</div>
                    <div class="code_t2">获取更多测试类型</div>
                    <img src="../assets/gzhcode.jpg" alt="">
                    <div class="code_btn" @click="share">我知道了</div>
                </div>
                <div class="code_box" v-else>
                    <div class="code_close" @click="share">×</div>
                    <div class="code_t1">请手动复制报告链接</div>
                    <div class="code_t2">{{shareUrl}}</div>
                    <div class="code_btn" @click="share">我已复制</div>
                </div>
            </div>
        </transition>
        <el-backtop/>
    </div>
</template>

<script>
    import ESTJ from "@/assets/ESTJ.png";
    import ENTP from "@/assets/ENTP.png";
    import ISTP from "@/assets/ISTP.png";
    import Report from '@/assets/result.json'

    export default {
        name: "Report",
        data() {
            return {
                reportData: [],
                isCode: false,
                codeNum: 0,
                shareUrl: "",
                reportDetailTitle: "",
                reportDetail: "",
                isDetail: false
            }
        },
        created() {
            const tradeId = this.$route.query.trade_id;
            if(tradeId){
                this.tradeId = tradeId;
                document.title = this.$store.state.title;
                this.getData();
            }else{
                this.$router.push({
                    name: "home"
                })
            }
        },
        methods:{
            async getData() {
                const res = await this.request.post("/api/get_report",{trade_id:this.tradeId});
                if(res.code == 1){
                    if(res.is_pay){
                        this.reportData = res.data;
                    }else{
                        this.open();
                    }
                }else{
                    await this.$router.push({
                        name: "home",
                    })
                }
            },
            open() {
                this.$confirm('你已测试，是否前往支付订单？', '温馨提示', {
                    confirmButtonText: '前往支付',
                    cancelButtonText: '重新测试',
                    type: 'success'
                }).then(() => {
                    this.$router.push({
                        name: "pay",
                        query: {
                            trade_id: this.tradeId,
                        }
                    })
                }).catch(() => {
                    this.$router.push({
                        name: "home",
                    })
                });
            },
            collect(){
                navigator.clipboard.writeText(window.location.href).then(() => {
                    this.$message({
                        message: '复制链接成功！',
                        type: 'success',
                        duration: 2000,
                        offset: 80
                    });
                }).catch((error) => {
                    this.codeNum = 1;
                    this.isCode = !this.isCode;
                    this.shareUrl = window.location.href;
                    // this.$message({
                    //     message: '复制链接失败！',
                    //     type: 'error',
                    //     duration: 2000,
                    //     offset: 80
                    // });
                })
            },
            async reTest(){
                const res = await this.request.post("/api/check_retest",{trade_id: this.tradeId});
                if(res.code == 1){
                    if(res.data == 0){
                        await this.$router.push({
                            name: "ceshi",
                            query: {
                                trade_id: this.tradeId,
                                retest: 1
                            }
                        })
                    }else{
                        this.$message({
                            message: '重测次数已用完！',
                            type: 'error',
                            duration: 2000,
                            offset: 80
                        });
                    }
                }else{
                    this.$message({
                        message: '网络错误！',
                        type: 'error',
                        duration: 2000,
                        offset: 80
                    });
                }
            },
            share(){
                this.codeNum = 0;
                this.isCode = !this.isCode;
            },
            showDetail(num){
                this.isDetail =!this.isDetail;
                if(num == 1){
                    this.reportDetailTitle = "职场报告";
                    this.reportDetail = this.reportData.report_work;
                }else if(num == 2){
                    this.reportDetailTitle = "婚恋报告";
                    this.reportDetail = this.reportData.report_love;
                }else if(num == 3){
                    this.reportDetailTitle = "八维报告";
                    this.reportDetail = this.reportData.report_bawei;
                }else{
                    this.reportDetailTitle = "";
                }
            }
        }
    }
</script>

<style scoped>

    .top_box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 80px;
        background: linear-gradient(90deg,#ffecd2,#f9e1bd);;
        font-size: 13px;
    }

    top_info{
        text-align: center;
        color: silver;
    }

    .menu{
        margin-top: 10px;
        width: calc(100% - 60px);
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .menu div{
        width: 70px;
        height: 25px;
        line-height: 25px;
        background: #54A0FD;
        color: white;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
    }

    .content {
        width: 100%;
    }

    .con_img {
        width: calc(100% - 40px);
        margin: 0 20px;
    }

    .result_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .box_left {
        flex: 2;
        color: #4E3D86 !important;
    }

    .box_left_t1 {
        margin: 0 0 0 20px;
        /*line-height: 25px;*/
    }

    .box_left_t2 {
        float: right;
    }

    .box_left_t21 {
        font-size: 68px;
        font-weight: 500;
        line-height: 70px;
    }

    .box_left_t22 {
        font-size: 20px;
        font-weight: 500;
    }

    .box_right {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .box_right img {
        width: 120px;
    }

    .result_chart {
        box-sizing: border-box;
        margin: 20px 30px;
    }

    .result_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
    }

    .result_left,
    .result_right {
        width: 88px;
        text-align: center;
        font-size: 15px;
    }

    .result_center {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        margin: 0 10px;
        border-radius: 10px;
        overflow: hidden;
    }

    .info_title{
        text-align: center;
        background: #54A0FD;
        height: 40px;
        line-height: 40px;
        width: calc(100% - 40px);
        margin: 10px 20px;
        font-size: 16px;
        color: white;
    }

    .info_box{
        width: calc(100% - 40px);
        margin: 0 20px;
        line-height: 1.8rem;
        text-align: justify;
    }

    .info_t1{
        text-align: center;
        color: #54A0FD;
        margin: 15px 0;
    }

    .info_t1 p:first-child{
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 3px;
    }

    .info_t1 p:last-child{
        font-size: 13px;
    }

    .letter_box{
        width: calc(100% - 60px);
        margin: 0 30px;
    }

    .letter_item{
        display: flex;
        margin: 15px 0;
        align-items: center;
    }

    .letter_left{
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin-right: 10px;
        text-align: center;
        border-radius: 50%;
        /*放置内容挤压变形*/
        flex-shrink: 0;
        font-size: 18px;
        font-weight: 500;
    }

    .letter_left_bg0{
        border: 6px solid #8876ff;
    }

    .letter_left_bg1{
        border: 6px solid #E4523B;
    }

    .letter_left_bg2{
        border: 6px solid #CF6FF1;
    }

    .letter_left_bg3{
        border: 6px solid #3D9388;
    }

    .letter_right{
        text-align: justify;
    }

    .code_bg{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: rgba(0,0,0,.5);
    }

    .code_box{
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 300px;
        transform: translateX(-50%) translateY(-50%);
        background: white;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .code_close{
        position: absolute;
        top: 5px;
        right: 12px;
        font-size: 25px;
    }

    .code_t1{
        font-size: 18px;
        text-align: center;
        margin-top: 20px;
    }

    .code_t2{
        font-size: 16px;
        max-width: 250px;
        text-align: center;
        margin: 10px;
        color: #8876ff;
        /*超出div自动换行*/
        white-space: normal;
        word-wrap: break-word;
    }

    .code_box img{
        width: 200px;
        height: 200px;
        margin: 0 auto;
    }

    .code_btn{
        width: 250px;
        height: 40px;
        margin: 20px;
        line-height: 40px;
        background: #54A0FD;
        text-align: center;
        border-radius: 50px;
        color: white;
    }

    .other_box{
        width: 100%;
        border-top: 5px solid #DEDEDE;
        margin-top: 20px;
    }

    .other_item{
        width: calc(100% - 40px);
        margin-left: 20px;
    }

    .report_btn{
        position: fixed;
        right: 0;
        top: 50%;
        z-index: 10;
        transform: translateY(-50%);
    }

    .report_btn div{
        background: rgba(0,0,0,.5);
        color: white;
        writing-mode: vertical-rl;
        margin: 10px 0;
        padding: 10px 3px;
        font-size: 13px;
    }

    .report_box{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 15;
        background: #54A0FD;
        overflow-y: scroll;
    }

    .report_close{
        position: fixed;
        bottom: 20px;
        left: 30px;
        width: calc(100% - 60px);
        height: 40px;
        line-height: 40px;
        border-radius: 50px;
        background: #8876ff;
        text-align: center;
        font-size: 15px;
        color: white;
    }

    .report_con{
        box-sizing: border-box;
        width: calc(100% - 30px);
        min-height: calc(100% - 30px);
        margin: 15px;
        background: white;
        border-radius: 10px;
        overflow: hidden;
        padding: 15px 0;
    }

    .report_con h3{
        text-align: center;
        margin-bottom: 15px;
    }

</style>