import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
        count: 0,
        title: "测试站点"
    },
    mutations: {
        increment (state) {
            state.count++
        },
        replaceText(state, newText) {
            state.title = newText;
        }
    }
})

export default store