<template>
    <div class="con">
        <el-container>
            <el-header class="header">
                <div class="title">MBTI性格测试专业版</div>
                <div class="num">最近30天测试人数：15315人</div>
                <el-steps :align-center="true" :active="cateActive" finish-status="success">
                    <el-step v-for="item in allData" :title="item.name"></el-step>
                </el-steps>
                <div class="prog">
                    <el-progress :percentage="getProgress" :stroke-width="10" :format="format"
                                 color="#54A0FD"></el-progress>
                </div>
            </el-header>
            <el-main class="main">
                <div class="problem">{{nowProblem.problem}}</div>
                <div class="answer" v-if="nowProblem.answer_a && nowProblem.answer_b">
                    <div class="item">
                        <div class="item_a item_a_bg">A</div>
                        <div class="item_b item_a_line" v-html="nowProblem.answer_a"></div>
                    </div>
                    <div class="item">
                        <div class="item_a item_b_bg">B</div>
                        <div class="item_b item_b_line" v-html="nowProblem.answer_b"></div>
                    </div>
                </div>
                <div class="answer" v-else>
                    <div class="item1" @click="handleAnswer(0,nowProblem.id)">
                        <div :class="result[resNum]!=undefined?(result[resNum].answer==0?'answer_active':''):''" class="item_b item_a_line" v-html="nowProblem.answer_a"></div>
                    </div>
                </div>
                <ul class="option" v-if="active!=(totalSubject-1) || (active==(totalSubject-1) && cateActive==(allData.length-1))">
                    <li :class="result[resNum]!=undefined?(result[resNum].answer==1?'answer_active':''):''" @click="handleAnswer(1,nowProblem.id)">非常像<span>A</span></li>
                    <li :class="result[resNum]!=undefined?(result[resNum].answer==2?'answer_active':''):''" @click="handleAnswer(2,nowProblem.id)">比较像<span>A</span></li>
                    <li :class="result[resNum]!=undefined?(result[resNum].answer==3?'answer_active':''):''" @click="handleAnswer(3,nowProblem.id)">居中</li>
                    <li :class="result[resNum]!=undefined?(result[resNum].answer==4?'answer_active':''):''" @click="handleAnswer(4,nowProblem.id)">比较像<span>B</span></li>
                    <li :class="result[resNum]!=undefined?(result[resNum].answer==5?'answer_active':''):''" @click="handleAnswer(5,nowProblem.id)">非常像<span>B</span></li>
                </ul>
                <div class="page">
                    <div @click="swichPage(-1)" v-if="resNum>0&&isResult"><i class="el-icon-back"></i>上一题</div>
                    <div v-else></div>
                    <div @click="swichPage(1)" v-if="resNum<result.length&&isResult">下一题<i class="el-icon-right"></i></div>
                    <div v-else></div>
                </div>
                <div class="result_btn" v-if="!isResult">
                    <button type="button" class="find_result_btn" @click="submitResult">查看结果</button>
                </div>
            </el-main>
            <el-footer>
                <div class="notice">
                    <p style="text-align: center;"><b>完成测试后，您将获得</b></p>
                    <ul class="content">
                        <li>获取您的4字母类型测试结果</li>
                        <li>发现适合于您性格类型的职业</li>
                        <li>知悉您的偏好优势和类型描述</li>
                        <li>评估您与恋人的长期相处情况</li>
                        <li>了解您的沟通风格和学习风格</li>
                        <li>查看与您分享同一性格的名人</li>
                    </ul>
                    <p style="text-align: center;">所有内容基于卡尔·荣格 (Carl Jung) 和伊莎贝尔·布里格斯·迈尔斯 (lsabel Briggs
                        Myers)的MBTI理论实证</p>
                </div>
            </el-footer>
        </el-container>
    </div>
</template>

<script>
    export default {
        name: "Ceshi",
        data() {
            return {
                cateActive: 0, //当前分类的位置
                active: 0, //当前答题的位置
                resNum: 0, //答题总数的计数器
                totalSubject: 1, //进度条的总数
                isResult: true, //判断答题是否结束
                allData: [], //问题接口的数据，包含分类
                nowProblem: [], //当前题目
                allProblem: [], //当前所在分类下的总题目
                result: [], //客户回答的存储数据
                tradeId: ""
            }
        },
        created() {
            // 获取路由对象里面的参数
            const tradeId = this.$route.query.trade_id;
            // 判断是否是重测的
            this.reTest = this.$route.query.retest;
            if(tradeId){
                this.tradeId = tradeId;
                document.title = this.$store.state.title;
                this.getData();
            }else{
                this.$router.push({
                    name: "home"
                })
            }
        },
        computed: {
            getProgress() {
                return (this.active + 1) / this.totalSubject * 100;
            }
        },
        methods: {
            async getData() {
                const res = await this.request.get("/api/question");
                // console.log(res);
                if (res.code == 1) {
                    this.allData = res.data;
                    this.totalSubject = res.data[this.cateActive].total;
                    this.allProblem = res.data[this.cateActive].data;
                    this.nowProblem = res.data[this.cateActive].data[this.active];
                }
            },
            format(percentage) {
                return (this.active + 1) + "/" + this.totalSubject;
            },
            handleAnswer(num, id) {
                if(num==0){
                    if(this.result[this.resNum]==undefined){
                        this.addResult(id, num);
                    }
                    if(this.result.length>0 && (this.resNum+1)%5 == 0){
                        this.saveTemp();
                    }
                    this.cateActive += 1;//类目的id
                    this.active = 0;//每个类目下的当前问题的序号索引
                    this.resNum += 1;//答题的位置索引
                    this.totalSubject = this.allData[this.cateActive].total;//切换每个类目总题目数量
                    this.allProblem = this.allData[this.cateActive].data;//切换内容题库
                    this.nowProblem = this.allData[this.cateActive].data[this.active];
                }else {
                    //判断是否为最后一个类型的最后一题
                    if(this.active==(this.totalSubject-1) && this.cateActive==(this.allData.length - 1)) {
                        //判断是否是当前正在回答的题目，还是回答过的
                        if (this.resNum < this.result.length) {
                            this.result[this.resNum].answer = num;
                        }else{
                            this.addResult(id, num);
                            //弹出最后查询结果的按钮
                            this.isResult = false;
                        }
                        this.saveTemp();
                    }else{
                        //判断是否回答过，回答过就修改，没回答过就新增
                        if (this.resNum < this.result.length) {
                            this.result[this.resNum].answer = num;
                        } else {
                            this.addResult(id, num);
                        }
                        if(this.result.length>0 && (this.resNum+1)%5 == 0){
                            this.saveTemp();
                        }
                        this.active += 1;
                        this.resNum += 1;
                        this.nowProblem = this.allProblem[this.active];
                    }
                }
            },
            addResult(id, num){
                this.result.push({
                    "id": id,
                    "answer": num
                });
            },
            async saveTemp(){
                const data = {
                    answer_result: this.result,
                    trade_id: this.tradeId
                }
                await this.request.post("/api/save_temp", data);
            },
            async submitResult(){
                const data = {
                    trade_id: this.tradeId
                };
                const res = await this.request.post("/api/save", data);
                // console.log(res);
                //判断是否为重新测试的情况
                if(res.code == 1){
                    if(this.reTest == 1){
                        const res1 = await this.request.post("/api/update_retest",data);
                        if(res1.code == 1){
                            await this.$router.push({
                                name: 'report',
                                query: {
                                    trade_id: this.tradeId,
                                }
                            })
                        }else{
                            this.$message({
                                message: '网络错误！',
                                type: 'error',
                                duration: 2000,
                            });
                        }
                    }else{
                        await this.$router.push({
                            name: 'result',
                            query: {
                                trade_id: this.tradeId,
                            }
                        })
                    }
                }
            },
            swichPage(num){
                if(num < 0 && this.active == 0){
                    this.cateActive += -1;
                    this.resNum += num;
                    this.totalSubject = this.allData[this.cateActive].total;
                    this.active = this.allData[this.cateActive].total - 1;
                    this.allProblem = this.allData[this.cateActive].data;
                    this.nowProblem = this.allData[this.cateActive].data[this.active];
                }else if(num > 0 && this.active == (this.totalSubject - 1)){
                    this.cateActive += 1;
                    this.resNum += num;
                    this.totalSubject = this.allData[this.cateActive].total;
                    this.active = 0;
                    this.allProblem = this.allData[this.cateActive].data;
                    this.nowProblem = this.allData[this.cateActive].data[this.active];
                }else{
                    this.active += num;
                    this.resNum += num;
                    this.nowProblem = this.allProblem[this.active];
                }
                // console.log("当前位置："+this.active+"，结果位置："+this.resNum);
                // console.log(this.result);
            }
        }
    }
</script>

<style scoped>

    .header {
        height: 180px !important;
        margin-bottom: 20px;
    }

    .prog {
        margin-top: 15px;
    }

    .main {
        background: white;
    }

    .problem {
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .answer {
        display: flex;
        justify-content: space-between;
        align-items: start;
    }

    .answer .item {
        width: 45%;
    }

    .answer .item .item_a {
        box-sizing: border-box;
        padding: 0 15px;
        height: 30px;
        line-height: 30px;
        color: white;
        margin: 15px 0;
    }

    .answer .item .item_b {
        padding: 0 10px;
    }

    .answer .item1 {
        width: 100%;
    }

    .answer .item1 .item_b {
        box-sizing: border-box;
        padding: 0 15px;
        height: 70px;
        line-height: 70px;
        border-top: 1px solid silver;
        border-bottom: 1px solid silver;
        border-right: 1px solid silver;
        border-radius: 10px;
        color: black;
        margin: 15px 0;
    }

    .item_a_bg{
        background: linear-gradient(270deg,#B0D966,#6CA835);
    }

    .item_a_line{
        border-left: 4px solid #6CA835;
    }

    .item_b_bg{
        background: linear-gradient(270deg, #F2D26C, #F0A589);
    }

    .item_b_line{
        border-left: 4px solid #F0A589;
    }

    .answer_active{
        background: #7CBD41!important;
        color: white!important;
    }

    .answer_active1{
        background: #7CBD41!important;
        color: white!important;
        margin-bottom: 35px!important;
    }

    ul{
        list-style: none!important;
    }

    .option {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 5px;
        margin: 30px 0;
    }

    .option li {
        flex: 1;
        display: flex;
        height: 60px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid silver;
        cursor: pointer;
        border-radius: 5px;
    }
    .page{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .page div{
        box-sizing: border-box;
        font-size: 16px;
        line-height: 35px;
        background: #54A0FD;
        color: white;
        padding: 0 20px;
        border-radius: 30px;
        text-align: center;
    }
    .notice{
        margin-top: 20px;
        color: #7e7e7e;
        font-size: 13px;
        line-height: 26px;
        background-color: #f9f9f9;
        padding: 12px 0 50px 0;
    }
    .notice .content{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .notice .content li {
        width: 49%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
    }

    /*router-link就是a*/
    .result_btn{
        margin: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .find_result_btn{
        background: #54A0FD;
        color: white;
        border-radius: 30px;
        border: 0px;
        width: calc(100% - 50px);
        height: 40px;
        font-size: 20px;
        font-weight: 500;
        max-width: 250px;
        animation: shan1 1s alternate infinite;
        cursor: pointer;
        text-align: center;
        line-height: 40px;
        text-decoration: none;
    }

    @keyframes shan1 {
        0%{
            transform: scale(.9);
        }
        100%{
            transform: scale(1);
        }
    }
</style>