<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>

    export default {
        name: 'App',
    }
</script>

<style>
    * {
        margin: 0;
        padding: 0;
    }

    html{
        font-size: 15px;
    }

    body {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        background: #f9f9f9;
    }

    a{
        text-decoration: none;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    #app {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .con {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
    }

    .home_img{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }

    .home_img img {
        width: 100% !important;
        height: auto !important;
        pointer-events: none;
    }

    .title {
        font-size: 24px;
        text-align: center;
        padding-top: 30px;
    }

    .num {
        font-size: 13px;
        color: silver;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .el-step__title {
        font-size: 15px !important;
        line-height: 30px !important;
    }

    .el-progress__text{
        font-size: 15px !important;
    }

    .slide-top-fade-enter-active {
        transition: all .5s ease;
    }

    .slide-top-fade-leave-active {
        transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-top-fade-enter, .slide-top-fade-leave-to
    {
        transform: translateY(100%);
        opacity: 0;
    }

    .el-tabs{
        width: calc(100% - 40px);
    }


    .xinmbti-zong{
        padding-bottom: 0.53333rem;
    }

    .xinmbti-zong h6{
        color: #f3764c;
        font-size: .48rem;
        text-align: center;
        background-color: #f9ead8;
        border: 0.02667rem solid #ffcfa8;
        padding: 0.26667rem;
    }

    .el-message-box{
        width: calc(100% - 60px)!important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    .item_a p,
    .item_b p{
        margin-bottom: 6px;
    }
</style>
